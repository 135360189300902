

























































import { Vue, Component } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import ClientTable from './ClientTable/ClientTable.vue';
import Modal from '@/components/poultry/Modal.vue';
import EditClientForm from './EditClientForm.vue';
import ClientDetailsTab from './ClientDetailsTab.vue';
import { ClientResponse, Client } from '@/models/poultry/client.model';
import { clientStores } from '@/store/poultry/client';
import { farmStores } from '@/store/poultry/farm/index';
import { Watch } from 'vue-property-decorator';
import { auditorStores } from '@/store/poultry/auditor/index';

@Component({
  components: {
    Page,
    ClientTable,
    Modal,
    ClientDetailsTab,
    EditClientForm
  }
})
export default class ClientDetails extends Vue {
  store = clientStores.details;
  farmCreateStore = farmStores.create;
  farmDetailStore = farmStores.details;
  auditorCreateStore = auditorStores.create;
  auditorDetailStore = auditorStores.details;
  showEditClientModal: boolean = false;

  created() {
    const species = (this.$router.currentRoute.fullPath.split('/'))[1];

    this.store.setSpecies(species);
    this.store.setClientId(Number(this.$route.params.id));
    this.store.fetchById(this.$route.params.id)
  }

  get client(): ClientResponse | Client {
    return this.store.client;
  }

  get isNewClientUpdated(): boolean {
    return this.store.isUpdated;
  }

  get isNewFarmCreated(): boolean {
    return this.farmCreateStore.isCreated;
  }

  get isNewFarmDeleted(): boolean {
    return this.farmDetailStore.isDeleted;
  }

  get isNewAuditorDeleted(): boolean {
    return this.auditorDetailStore.isDeleted;
  }

  @Watch('isNewFarmDeleted')
  @Watch('isNewAuditorDeleted')
  @Watch('isNewFarmCreated')
  @Watch('isNewClientUpdated')
  onCountUpdate() {
    this.store.fetchById(this.$route.params.id);
  }

  handleEditClient() {
    this.showEditClientModal = true;
  }
}
