





import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import Tab, { TabRoutes } from '@/components/poultry/Tab.vue';

@Component({
  components: {
    Tab
  }
})
export default class ClientDetailsTab extends Vue {
  tabRoutes: TabRoutes[] = [
    { text: 'Farms', name: 'client.details.farms' },
    { text: 'Auditors', name: 'client.details.auditors' },
  ];
}
