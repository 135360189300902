













import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

export interface TabRoutes {
  name: string;
  text: string;
}

@Component({})
export default class Tab extends Vue {
  @Prop({
    type: Array,
    default: []
  })
  private routes!: TabRoutes[];
}
