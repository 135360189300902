






























































































import { Component, Vue, Emit } from 'vue-property-decorator';
import Modal from '@/components/poultry/Modal.vue';
import VueRouter from 'vue-router';
import Dropdown from '@/components/poultry/Dropdown.vue';
import { clientStores } from '@/store/poultry/client';
import { Country, Region, City } from '@/models/poultry/country.model';
import { ToastProgrammatic as Toast } from 'buefy';
import DeleteClient from './DeleteClient.vue';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/store/poultry/endpoints';

@Component({
  components: {
    Modal,
    Dropdown,
    DeleteClient,
  },
})
export default class EditClientForm extends Vue {
  $router!: VueRouter;
  store = clientStores.details;
  client = this.store.client;
  showDeleteModal = false;
  countries: Country[] = [];
  states: Region[] = [];
  cities: City[] = [];

  async created() {
    await this.store.fetchById(this.$route.params.id);
    await this.fetchCountriesDropdown();
    await this.fetchStateDropdown();
    await this.fetchCitiesDropdown();
  }

  async fetchCountriesDropdown() {
    try {
      const { data } = await axios.get<Country[]>(Endpoints.Countries);
      this.countries = data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchStateDropdown() {
    try {
      const params = {} as { [key: string]: string };

      if (this.client.countryId) {
        params['country'] = this.client.countryId;
      }

      const { data } = await axios.get<Region[]>(Endpoints.States, {
        params,
      });

      this.states = data;
    } catch (error) {
      console.error(error);
    }
  }

  async fetchCitiesDropdown() {
    try {
      const params = {} as { [key: string]: string };

      if (this.client.stateId) {
        params['state'] = this.client.stateId;
      }

      const { data } = await axios.get(Endpoints.Cities, {
        params,
      });

      this.cities = data;
    } catch (error) {
      console.error(error);
    }
  }

  async handleFormSubmitted() {
    try {
      if (this.client.name && this.client.countryId && this.client.cityId && this.client.stateId) {
        this.store.updateClient(this.client);
        await this.store.update();

        if (this.store.isUpdated) {
          Toast.open({
            type: 'is-success',
            message: 'Client successfully updated',
          });
          this.closeEditClientModal();
        }
      } else {
        Toast.open({
          type: 'is-danger',
          message: 'Required fields cannot be empty',
        });
      }
    } catch (error) {
      Toast.open({
        type: 'is-danger',
        message: 'Client cannot be updated',
      });
    }
  }

  handleLocationChanged(type: string) {
    if (type === 'city') {
      // Nothing to do here
      return;
    } else if (type === 'country') {
      if (!this.client.countryId) {
        this.states = [];
        this.cities = [];
        this.client.stateId = '';
        this.client.cityId = '';
      } else {
        this.fetchStateDropdown();
      }
    } else if (type === 'state') {
      if (!this.client.stateId) {
        this.client.cityId = '';
        this.cities = [];
      } else {
        this.fetchCitiesDropdown();
      }
    }
  }

  closeDeleteClientModal() {
    this.showDeleteModal = false;
    this.closeEditClientModal();
  }

  @Emit('onClose')
  closeEditClientModal() {}
}
